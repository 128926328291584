.scroll-menu {
    width: 100%;
    height: 32px;
    overflow: auto;
    -ms-overflow-style: none;
    white-space: nowrap;
    /* IE, Edge 対応 */
    scrollbar-width: none;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: auto;
}

.scroll-menu::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
}

.scroll-menu a {
    display: inline-block;
    text-align: center;
    text-decoration: none;
}