.footer-container {
    width: cacl(100vw - 20px);
    padding-left: 20px;
    /* margin-bottom: 96px; */
}

.footer-container-brand {
    font-family: Noto Sans JP;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-align: center;
}

.footer-container h2 {
    font-family: Noto Sans JP;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin: 36px auto 12px auto;
}

.footer-container-brand h2 {
    font-family: Noto Sans JP;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin-top: 114px;
    margin-bottom: 0;
    line-height: 16px;
}

.footer-menu {
    display: flex;
}

.footer-menu a {
    font-size: 10px;
    color: #000000;
    display: inline-block;
    text-decoration: none;
}

.footer-menu :first-child {
    margin: 0;
    padding-right: 20px;
}

.footer-menu :not(:first-child) {
    padding: 0 20px;
}

.footer-menu :not(:last-child) {
    border-right: 1px solid #000000;
}

.footer-menu-brand {
    justify-content: 'center'
}

.footer-menu-logo {
    display: inline-block;
    width: 48px;
    height: 16px;
}

.footer-menu-brand a {
  
    color: #000000;
    display: inline-block;
    text-decoration: none;
}

.footer-menu-brand :first-child {
    margin: 0;
    padding-right: 20px;
}

.footer-menu-brand :not(:first-child) {
    padding: 0 20px;
}

.footer-menu-brand :not(:last-child) {
    border-right: 1px solid #000000;
}



@media screen and (min-width:1000px){
    .footer-menu-brand a{
        font-size: 20px;
    }
}
@media screen and (max-width:1000px){
    .footer-menu-brand a{
        font-size: 14px;
    }
}