.notfoundpage-container {
    margin-top: 48px;
    margin-bottom: 58px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    /* or 200% */
    text-align: center;
}

.notfoundpage-area {
    display: block;
    padding-top: 112px;
    margin-bottom: 98px;
}

.notfoundpage-title {
    display: block;
    text-align: center;
    margin: 0 auto;
    height: 80px;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
}

.notfoundpage-subtitle {
    margin-top: 20px;
    height: 48px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
}