@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans JP', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.brandpage-area {
    display: block;
    padding-top: 112px;
    margin-bottom: 98px;
}

.brandpage-title-image {
    display: block;
    margin: 0 auto;
    width: 238px;
    object-fit: cover;
}

.brandpage-subtitle {
    margin-top: 36px;
    height: 48px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
}

.brandpage-container {
    margin-top: 80px;
    margin-bottom: 90px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    /* or 200% */
    text-align: center;
}


/* TOPエリア */

.brandpage-pc {
    padding-top: 140px;
    padding-bottom: 76px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1300px;
    min-width: 600px;
    padding-left: 50px;
    padding-right: 50px;

}

.brandpage-pc img {
    display: block;
    width: 400px;
}

.brandpage-pc-message {
    margin-top: 99px;
}

.brandpage-pc-message h2 {
    margin-top: 60px;
    font-size: 36px;
    letter-spacing: 0.15em;
}

.brandpage-pc-message p {
    margin-top: 20px;
    font-size: 20px;
    letter-spacing: 0.15em;
    font-weight: 300;
}

@media screen and (max-width:1000px) {
    .brandpage-pc {
        display: none;
    }
}




.brandpage-sp {
    width: 320px;
    margin: 0 auto;
    padding-top: 72px;
    padding-bottom: 60px;
}

.brandpage-sp img {
    display: block;
    margin: 0 auto;
    width: 85%;

}

.brandpage-top-logo {
    width: 160px !important;
    display: block;
}

.brandpage-sp-message {
    width: 90%;
    margin: 0 auto;
}

.brandpage-sp-message h2 {
    font-family: 'Noto Sans JP';
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    letter-spacing: 0.15em;
    margin: 0 auto 20px auto;
}

.brandpage-sp-message p {

    font-size: 12px;
    letter-spacing: 0.15em;
    font-weight: 300;
    line-height: 24px;
}


.brandpage-sp-img {
    padding-top: 40px;
    padding-bottom: 52px;

}

@media screen and (min-width:1000px) {
    .brandpage-sp {
        display: none;
    }
}

/* 受付エリア */
@media screen and (min-width:1000px) {
    .brandpage-reseption {
        background-color: #333333;
        width: 100vw;
        padding-top: 50px;
        padding-bottom: 50px;
        ;

    }

    .brandpage-reseption h2 {
        font-size: 36px;
        text-align: center;
        color: white;
        letter-spacing: 0.15em;
        margin-bottom: 48px;

    }

    .brandpage-reseption p {
        font-size: 20px;
        text-align: center;
        color: white;
        letter-spacing: 0.15em;
        font-weight: bold;
        margin: 0;

    }

    .brandpage-reseption img {
        display: block;


        text-decoration: none;
        width: 160px;

        margin: 0 auto;
        margin-top: 24px;

    }

    .brandpage-sp-reseption-message {
        font-family: 'Noto Sans JP';
        font-weight: 1000;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15em;
        color: white;

    }

    .brandpage-sp-reseption-message {
        display: none;
    }
}

@media screen and (max-width:1000px) {
    .brandpage-reseption {
        background-color: #333333;
        width: 100vw;
        padding-top: 48px;
        padding-bottom: 48px;
        ;

    }

    .brandpage-reseption h2 {
        font-size: 24px;
        text-align: center;
        color: white;
        letter-spacing: 0.15em;
        margin-bottom: 36px;

    }

    .brandpage-reseption p {
        font-size: 12px;
        text-align: center;
        color: white;
        letter-spacing: 0.15em;
        font-weight: bold;
        margin: 0;

    }

    .brandpage-reseption img {
        display: block;
        width: 136px;
        text-decoration: none;
        margin: 0 auto;
        margin-top: 24px;

    }

    .brandpage-pc-reseption-message {
        display: none;
    }
}

/* メイン訴求エリア */
@media screen and (min-width:1000px) {
    .brandpage-main {
        padding-left: 0 50px;
        padding-top: 100px;
        min-width: 900px;
        max-width: 1500px;
        margin: 0 auto;

    }

    .brandpage-main-topimg {

        display: block;
        margin: 0 auto;
    }

    .brandpage-main-topimg-sp {

        display: none;
    }

    .brandpage-main-container {
        border: solid 2px #000000;
        border-radius: 48px;

        height: 1920px;
        margin-left: 50px;
        margin-right: 50px;
        padding-bottom: 64px;


    }

    .brandpage-main-part {
        display: flex;
        margin: 100px 100px 0px 100px;
        justify-content: space-between;
        align-items: center;
    }


    .brandpage-feature {
        width: 440px;
        max-width: 30vw;
    }

    .brandpage-featureimg {
        width: 400px;
        max-width: 30%;
        display: block;
    }

    .brandpage-main-part h2 {
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 0.3em;
        font-weight: 500;
        margin-top: 32px;
    }

    .brandpage-main-part p {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.15em;

        margin-top: 32px;
    }

}

@media screen and (max-width:1000px) {
    .brandpage-main {
        padding-top: 64px;
        min-width: 300px;
        max-width: 400px;
        margin: 0 auto;

    }

    .brandpage-main-topimg {

        display: none
    }

    .brandpage-main-topimg-sp {

        display: block;
        margin: 0 auto;
    }


    .brandpage-main-container {
        border: solid 2px #000000;
        border-radius: 48px;


        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 80px;


    }

    .brandpage-main-part {
        margin: 50px 20px 20px 20px;
        justify-content: space-between;
        align-items: center;

    }


    .brandpage-feature {
        width: 90%;
        display: block;
        margin: 0 auto;
    }

    .brandpage-featureimg {
        width: 80%;
        display: block;
        margin: 40px auto 0 auto;
    }

    .brandpage-main-part h2 {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.15em;
        font-weight: 500;
        width: 90%;
        margin: 24px auto 0 auto;
    }

    .brandpage-main-part p {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.15em;

        width: 90%;
        margin: 24px auto 0 auto;
    }

}

.brandpage-footer {
    padding-top: 100px;
    width: 300px;
    text-align: center;
    margin: 0 auto 48px auto;
}

/* .brandpage-sns{
    display: flex;
    justify-content: space-between;
} */

.brandpage-footer a {
    margin: 0 16px;
}

@media screen and (min-width:1000px) {
    .brandpage-sp-only {
        display: none;
    }

    .brandpage-sns {
        margin: 48px auto;

    }

    .brandpage-sns img {
        width: 64px;
        height: 64px;

    }

    .brandpage-footer-logo {
        width: 220px;
    }
}

@media screen and (max-width:1000px) {
    .brandpage-pc-only {
        display: none;
    }

    .brandpage-sns {
        margin: 48px auto;

    }

    .brandpage-sns img {
        width: 48px;
        height: 48px;

    }

    .brandpage-footer-logo {
        display: inline-block;
        width: 180px;
        height: 52px;

    }
}
.footer-container {
    width: cacl(100vw - 20px);
    padding-left: 20px;
    /* margin-bottom: 96px; */
}

.footer-container-brand {
    font-family: Noto Sans JP;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    text-align: center;
}

.footer-container h2 {
    font-family: Noto Sans JP;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin: 36px auto 12px auto;
}

.footer-container-brand h2 {
    font-family: Noto Sans JP;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin-top: 114px;
    margin-bottom: 0;
    line-height: 16px;
}

.footer-menu {
    display: flex;
}

.footer-menu a {
    font-size: 10px;
    color: #000000;
    display: inline-block;
    text-decoration: none;
}

.footer-menu :first-child {
    margin: 0;
    padding-right: 20px;
}

.footer-menu :not(:first-child) {
    padding: 0 20px;
}

.footer-menu :not(:last-child) {
    border-right: 1px solid #000000;
}

.footer-menu-brand {
    justify-content: 'center'
}

.footer-menu-logo {
    display: inline-block;
    width: 48px;
    height: 16px;
}

.footer-menu-brand a {
  
    color: #000000;
    display: inline-block;
    text-decoration: none;
}

.footer-menu-brand :first-child {
    margin: 0;
    padding-right: 20px;
}

.footer-menu-brand :not(:first-child) {
    padding: 0 20px;
}

.footer-menu-brand :not(:last-child) {
    border-right: 1px solid #000000;
}



@media screen and (min-width:1000px){
    .footer-menu-brand a{
        font-size: 20px;
    }
}
@media screen and (max-width:1000px){
    .footer-menu-brand a{
        font-size: 14px;
    }
}
html {
    margin: 0;
    padding: 0;
}

header {
    height: 48px;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #E5E5E5;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #F7F7F7;
    z-index: 10;
    width: 100vw;
    display: flex;
    justify-content: space-between;
}

header img {
    height: 24px;
    display: inline-block;
    margin: 12.9PX auto 12.9px 20px;
}

.pagetemplate-inner-container {
    background-color: #F7F7F7;
    min-height: 100vh;
}

@media screen and (max-width:600px) {
    .pagetemplate-inner-container {
        margin: 0;
        margin-top: 48px;
        min-width: 320px;
    }
}

@media screen and (min-width:600px) {
    .pagetemplate-inner-container {
        margin: 0 auto;
        margin-top: 48px;
        width: 600px;
    }
}

.pagetemplate-outer-container {
    background-color: #F7F7F7;
    margin: 0;
    padding: 0;
    padding-bottom: 130px;
    width: 100vw;
}

.lppagetemplate-outer-container header {
    height: 48px;
    margin: 0;
    border-bottom: 1px solid #E5E5E5;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #ffffff;
    z-index: 10;
    width: 100vw;
    display: flex;
    justify-content: space-between;

}

.lppagetemplate-outer-container {
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    padding-bottom: 130px;
    width: 100vw;
}

.lppagetemplate-inner-container {
    background-color: #ffffff;
    min-height: 100vh;
}
.BlackButton {
  /* display: flex;
    flex-direction: row;
    align-items: flex-start; */
  padding: 18px 24px;
  /* position: absolute; */
  justify-content: 'center';
  /* width: 252px; */
  /* left: 375px;
  top: 692px; */
  border-radius: 12px;
  background-color: black;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  /* line-height: 12px; */
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.BlackButton2 {
  width:104px;
  height:28px;
  border-radius: 20px;
  line-height: 28px;
  font-size: 12px;
  padding: auto 16px;
  background-color: black;
  color: white;
  font-weight: bold;
  text-decoration: none;
  display: block;
  text-align: center;
  margin: auto 12px auto 0;
}

.gformbtn-container a {
  text-decoration: none;
}

.gformbtn-btn {
  position: static;
  display: block;
  margin: 0px auto;
  padding: 18px 24px;
  border: 0;
  text-align: center;
  border-radius: 12px;
  background-color: black;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  line-height: 12px;
  width: 252px;
  cursor: pointer;
}
.carousel-container {
    padding: auto;
    display: flex !important;
    height: 100%;
    justify-content: center;
    margin: auto;
}

.carousel-container img {
    object-fit: contain;
    height: calc(100% - 64px);
    width: calc(100% - 64px);
    max-height: 200px;
    display: inline-block;
    margin: 16px auto;
}

@media screen and (min-width:600px) {
    .carousel-container img {
        max-height: 440px;
    }
}
.itemComment-container {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
}

.itemcomment-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.itemcomment-header img {
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    object-fit: cover;
}

.itemcomment-header h2 {
    font-size: 12px;
    color: black;
    margin: 8px;
    width: calc(100% - 48px);
}

.itemComment-sharebutton {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.itemComment-container h1 {
    margin: 0;
    font-size: 16px;
    color: black;
    margin-bottom: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.itemComment-container p {
    margin: 0;
    font-size: 12px;
    color: black;
    white-space: pre-wrap;
}
.shoppingbtn-container {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 76px;
    box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.shoppingbtn-btn {
    text-decoration: none;
    width: 280px;
    height: 48px;
    border-radius: 16px;
    display: flex;
    margin: auto;
    margin-top: 14px;
}

.shoppingbtn-icon {
    width: 52px;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    border: solid 1px black;
    display: flex;
    align-items: center;
}

.shoppingbtn-btn img {
    width: 36px;
    height: auto;
    display: block;
    margin: auto;
}

.shoppingbtn-label {
    width: 228px;
    background-color: black;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shoppingbtn-label h3 {
    color: white;
    margin-left: 12px;
    padding: 0;
    font-size: 10px;
}

.shoppingbtn-label h2 {
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 0;
    margin-right: 12px;
}
.shoppingbtn-original-label {
    width:90%;
    background-color: black;
    border-radius: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.shoppingbtn-original-label h2 {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding: 0;
    margin:auto;
}
.itempage-container {
    padding: 12px 20px;
}

.itempage-image {
    margin: 0px auto 34px auto;
    width: calc(100%);
    background-color: white;
    border-radius: 16px;
}

.itempage-comment {
    width: calc(100% - 40px);
}
.notfoundpage-container {
    margin-top: 48px;
    margin-bottom: 58px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    /* or 200% */
    text-align: center;
}

.notfoundpage-area {
    display: block;
    padding-top: 112px;
    margin-bottom: 98px;
}

.notfoundpage-title {
    display: block;
    text-align: center;
    margin: 0 auto;
    height: 80px;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
}

.notfoundpage-subtitle {
    margin-top: 20px;
    height: 48px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
}
.topbtn-container a {
  text-decoration: none;
}

.topbtn-btn {
  position: static;
  display: block;
  margin: 0px auto;
  padding: 18px 24px;
  border: 0;
  text-align: center;
  border-radius: 12px;
  background-color: black;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  line-height: 12px;
  width: 156px;
  cursor: pointer;
}
.privacypolicypage-container {
    padding: 0 25px;
}
.termpage-container {
    padding: 0 25px;
}
.item-container {
    padding: 12px;
    background-color: white;
    border-radius: 16px;
    width: 85%;
    margin: auto;
}

.item-container img {
    display: block;
    max-height: 200px;
    max-width: 100%;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
}

.item-container p {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: black;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.accountinfo-bgImage {
    height: 27vw;
    width: 100%;
    display: inline-block;
    margin-bottom: 0;
    padding: 0;
    object-fit: cover;
}

@media screen and (max-width:600px) {
    .accountinfo-bgImage {
        height: 27vw;
    }
}

@media screen and (min-width:600px) {
    .accountinfo-bgImage {
        height: 165px;
    }
}

.accountinfo-account-container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.accountinfo-accountIcon {
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    width: 80px;
    height: 80px;
    padding: 0;
    margin-top: -45px;
    margin-left: 20px;
    object-fit: cover;
}

.accountinfo-accountIcon-placeholder {
    width: 80px;
    height: 80px;
    padding: 0;
    margin-top: -40px;
    margin-left: 20px;
}

.accountinfo-sharebutton {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.accountinfo-share {
    width: 24px;
    height: 24px;
    margin: 13px 20px auto auto;
}

.accountinfo-accountName {
    padding: 0;
    margin: 13px auto 0px 20px;
    font-size: 16px;
    font-family: 'Noto Sans JP';
}

.accountinfo-accountId {
    color: #808080;
    font-size: 12px;
    font-family: 'Noto Sans JP';
    margin: 0;
    padding: 0;
    margin-left: 20px;
}

.accountinfo-accountDetail-container{
    margin: 12px 20px 30px 20px;
}
.accountinfo-accountDetail {
    font-size: 12px;
    line-height: 18px;
    font-family: Noto Sans JP;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    white-space: pre-wrap;
}
.accountinfo-url{
    font-size: 10px;
    font-family: 'Noto Sans JP';
    color: #00366A;
    margin-bottom: 3px;

    
}
.accountinfo-linkImg{
    width: 14px;
    height: 14px;
    margin-right:4px;
   
}

.accountinfo-linkContainer{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 300px; /* 省略せずに表示するサイズを指定 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;  
	-o-text-overflow: ellipsis; 
}
.menuitem-selected-container {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    border-bottom: 2px solid black;
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
    z-index: 100;
    margin: 0 15px;
}

.menuitem-non-selected-container {
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
            appearance: none;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #808080;
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
    margin: 0 15px;
    text-decoration: none;
    cursor: pointer;
}

.menuitem-non-selected-container:hover {
    color: black;
}
.scroll-menu {
    width: 100%;
    height: 32px;
    overflow: auto;
    -ms-overflow-style: none;
    white-space: nowrap;
    /* IE, Edge 対応 */
    scrollbar-width: none;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: auto;
}

.scroll-menu::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
}

.scroll-menu a {
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.my-gallery-class {
    width: 100%;
    margin: 20px auto;
}
.categorycomment-container {
    width: calc(96% - 40px);
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    font-size: 12px;
    color: black;
    border-radius: 20px;
    white-space: pre-wrap;
}
.toppage-maincontaints {
    padding: 0 20px;
}
