.carousel-container {
    padding: auto;
    display: flex !important;
    height: 100%;
    justify-content: center;
    margin: auto;
}

.carousel-container img {
    object-fit: contain;
    height: calc(100% - 64px);
    width: calc(100% - 64px);
    max-height: 200px;
    display: inline-block;
    margin: 16px auto;
}

@media screen and (min-width:600px) {
    .carousel-container img {
        max-height: 440px;
    }
}