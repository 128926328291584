.BlackButton {
  /* display: flex;
    flex-direction: row;
    align-items: flex-start; */
  padding: 18px 24px;
  /* position: absolute; */
  justify-content: 'center';
  /* width: 252px; */
  /* left: 375px;
  top: 692px; */
  border-radius: 12px;
  background-color: black;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  /* line-height: 12px; */
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.BlackButton2 {
  width:104px;
  height:28px;
  border-radius: 20px;
  line-height: 28px;
  font-size: 12px;
  padding: auto 16px;
  background-color: black;
  color: white;
  font-weight: bold;
  text-decoration: none;
  display: block;
  text-align: center;
  margin: auto 12px auto 0;
}
