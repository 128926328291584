.brandpage-area {
    display: block;
    padding-top: 112px;
    margin-bottom: 98px;
}

.brandpage-title-image {
    display: block;
    margin: 0 auto;
    width: 238px;
    object-fit: cover;
}

.brandpage-subtitle {
    margin-top: 36px;
    height: 48px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
}

.brandpage-container {
    margin-top: 80px;
    margin-bottom: 90px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    /* or 200% */
    text-align: center;
}


/* TOPエリア */

.brandpage-pc {
    padding-top: 140px;
    padding-bottom: 76px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1300px;
    min-width: 600px;
    padding-left: 50px;
    padding-right: 50px;

}

.brandpage-pc img {
    display: block;
    width: 400px;
}

.brandpage-pc-message {
    margin-top: 99px;
}

.brandpage-pc-message h2 {
    margin-top: 60px;
    font-size: 36px;
    letter-spacing: 0.15em;
}

.brandpage-pc-message p {
    margin-top: 20px;
    font-size: 20px;
    letter-spacing: 0.15em;
    font-weight: 300;
}

@media screen and (max-width:1000px) {
    .brandpage-pc {
        display: none;
    }
}




.brandpage-sp {
    width: 320px;
    margin: 0 auto;
    padding-top: 72px;
    padding-bottom: 60px;
}

.brandpage-sp img {
    display: block;
    margin: 0 auto;
    width: 85%;

}

.brandpage-top-logo {
    width: 160px !important;
    display: block;
}

.brandpage-sp-message {
    width: 90%;
    margin: 0 auto;
}

.brandpage-sp-message h2 {
    font-family: 'Noto Sans JP';
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    letter-spacing: 0.15em;
    margin: 0 auto 20px auto;
}

.brandpage-sp-message p {

    font-size: 12px;
    letter-spacing: 0.15em;
    font-weight: 300;
    line-height: 24px;
}


.brandpage-sp-img {
    padding-top: 40px;
    padding-bottom: 52px;

}

@media screen and (min-width:1000px) {
    .brandpage-sp {
        display: none;
    }
}

/* 受付エリア */
@media screen and (min-width:1000px) {
    .brandpage-reseption {
        background-color: #333333;
        width: 100vw;
        padding-top: 50px;
        padding-bottom: 50px;
        ;

    }

    .brandpage-reseption h2 {
        font-size: 36px;
        text-align: center;
        color: white;
        letter-spacing: 0.15em;
        margin-bottom: 48px;

    }

    .brandpage-reseption p {
        font-size: 20px;
        text-align: center;
        color: white;
        letter-spacing: 0.15em;
        font-weight: bold;
        margin: 0;

    }

    .brandpage-reseption img {
        display: block;


        text-decoration: none;
        width: 160px;

        margin: 0 auto;
        margin-top: 24px;

    }

    .brandpage-sp-reseption-message {
        font-family: 'Noto Sans JP';
        font-weight: 1000;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15em;
        color: white;

    }

    .brandpage-sp-reseption-message {
        display: none;
    }
}

@media screen and (max-width:1000px) {
    .brandpage-reseption {
        background-color: #333333;
        width: 100vw;
        padding-top: 48px;
        padding-bottom: 48px;
        ;

    }

    .brandpage-reseption h2 {
        font-size: 24px;
        text-align: center;
        color: white;
        letter-spacing: 0.15em;
        margin-bottom: 36px;

    }

    .brandpage-reseption p {
        font-size: 12px;
        text-align: center;
        color: white;
        letter-spacing: 0.15em;
        font-weight: bold;
        margin: 0;

    }

    .brandpage-reseption img {
        display: block;
        width: 136px;
        text-decoration: none;
        margin: 0 auto;
        margin-top: 24px;

    }

    .brandpage-pc-reseption-message {
        display: none;
    }
}

/* メイン訴求エリア */
@media screen and (min-width:1000px) {
    .brandpage-main {
        padding-left: 0 50px;
        padding-top: 100px;
        min-width: 900px;
        max-width: 1500px;
        margin: 0 auto;

    }

    .brandpage-main-topimg {

        display: block;
        margin: 0 auto;
    }

    .brandpage-main-topimg-sp {

        display: none;
    }

    .brandpage-main-container {
        border: solid 2px #000000;
        border-radius: 48px;

        height: 1920px;
        margin-left: 50px;
        margin-right: 50px;
        padding-bottom: 64px;


    }

    .brandpage-main-part {
        display: flex;
        margin: 100px 100px 0px 100px;
        justify-content: space-between;
        align-items: center;
    }


    .brandpage-feature {
        width: 440px;
        max-width: 30vw;
    }

    .brandpage-featureimg {
        width: 400px;
        max-width: 30%;
        display: block;
    }

    .brandpage-main-part h2 {
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 0.3em;
        font-weight: 500;
        margin-top: 32px;
    }

    .brandpage-main-part p {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.15em;

        margin-top: 32px;
    }

}

@media screen and (max-width:1000px) {
    .brandpage-main {
        padding-top: 64px;
        min-width: 300px;
        max-width: 400px;
        margin: 0 auto;

    }

    .brandpage-main-topimg {

        display: none
    }

    .brandpage-main-topimg-sp {

        display: block;
        margin: 0 auto;
    }


    .brandpage-main-container {
        border: solid 2px #000000;
        border-radius: 48px;


        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 80px;


    }

    .brandpage-main-part {
        margin: 50px 20px 20px 20px;
        justify-content: space-between;
        align-items: center;

    }


    .brandpage-feature {
        width: 90%;
        display: block;
        margin: 0 auto;
    }

    .brandpage-featureimg {
        width: 80%;
        display: block;
        margin: 40px auto 0 auto;
    }

    .brandpage-main-part h2 {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.15em;
        font-weight: 500;
        width: 90%;
        margin: 24px auto 0 auto;
    }

    .brandpage-main-part p {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.15em;

        width: 90%;
        margin: 24px auto 0 auto;
    }

}

.brandpage-footer {
    padding-top: 100px;
    width: 300px;
    text-align: center;
    margin: 0 auto 48px auto;
}

/* .brandpage-sns{
    display: flex;
    justify-content: space-between;
} */

.brandpage-footer a {
    margin: 0 16px;
}

@media screen and (min-width:1000px) {
    .brandpage-sp-only {
        display: none;
    }

    .brandpage-sns {
        margin: 48px auto;

    }

    .brandpage-sns img {
        width: 64px;
        height: 64px;

    }

    .brandpage-footer-logo {
        width: 220px;
    }
}

@media screen and (max-width:1000px) {
    .brandpage-pc-only {
        display: none;
    }

    .brandpage-sns {
        margin: 48px auto;

    }

    .brandpage-sns img {
        width: 48px;
        height: 48px;

    }

    .brandpage-footer-logo {
        display: inline-block;
        width: 180px;
        height: 52px;

    }
}