html {
    margin: 0;
    padding: 0;
}

header {
    height: 48px;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #E5E5E5;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #F7F7F7;
    z-index: 10;
    width: 100vw;
    display: flex;
    justify-content: space-between;
}

header img {
    height: 24px;
    display: inline-block;
    margin: 12.9PX auto 12.9px 20px;
}

.pagetemplate-inner-container {
    background-color: #F7F7F7;
    min-height: 100vh;
}

@media screen and (max-width:600px) {
    .pagetemplate-inner-container {
        margin: 0;
        margin-top: 48px;
        min-width: 320px;
    }
}

@media screen and (min-width:600px) {
    .pagetemplate-inner-container {
        margin: 0 auto;
        margin-top: 48px;
        width: 600px;
    }
}

.pagetemplate-outer-container {
    background-color: #F7F7F7;
    margin: 0;
    padding: 0;
    padding-bottom: 130px;
    width: 100vw;
}

.lppagetemplate-outer-container header {
    height: 48px;
    margin: 0;
    border-bottom: 1px solid #E5E5E5;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #ffffff;
    z-index: 10;
    width: 100vw;
    display: flex;
    justify-content: space-between;

}

.lppagetemplate-outer-container {
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    padding-bottom: 130px;
    width: 100vw;
}

.lppagetemplate-inner-container {
    background-color: #ffffff;
    min-height: 100vh;
}