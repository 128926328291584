@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

.item-container {
    padding: 12px;
    background-color: white;
    border-radius: 16px;
    width: 85%;
    margin: auto;
}

.item-container img {
    display: block;
    max-height: 200px;
    max-width: 100%;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
}

.item-container p {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: black;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}