.itempage-container {
    padding: 12px 20px;
}

.itempage-image {
    margin: 0px auto 34px auto;
    width: calc(100%);
    background-color: white;
    border-radius: 16px;
}

.itempage-comment {
    width: calc(100% - 40px);
}