.categorycomment-container {
    width: calc(96% - 40px);
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    font-size: 12px;
    color: black;
    border-radius: 20px;
    white-space: pre-wrap;
}