.menuitem-selected-container {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    border-bottom: 2px solid black;
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
    z-index: 100;
    margin: 0 15px;
}

.menuitem-non-selected-container {
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #808080;
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
    margin: 0 15px;
    text-decoration: none;
    cursor: pointer;
}

.menuitem-non-selected-container:hover {
    color: black;
}