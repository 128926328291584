.shoppingbtn-container {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 76px;
    box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.shoppingbtn-btn {
    text-decoration: none;
    width: 280px;
    height: 48px;
    border-radius: 16px;
    display: flex;
    margin: auto;
    margin-top: 14px;
}

.shoppingbtn-icon {
    width: 52px;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    border: solid 1px black;
    display: flex;
    align-items: center;
}

.shoppingbtn-btn img {
    width: 36px;
    height: auto;
    display: block;
    margin: auto;
}

.shoppingbtn-label {
    width: 228px;
    background-color: black;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shoppingbtn-label h3 {
    color: white;
    margin-left: 12px;
    padding: 0;
    font-size: 10px;
}

.shoppingbtn-label h2 {
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 0;
    margin-right: 12px;
}
.shoppingbtn-original-label {
    width:90%;
    background-color: black;
    border-radius: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.shoppingbtn-original-label h2 {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding: 0;
    margin:auto;
}