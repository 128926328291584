@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
.accountinfo-bgImage {
    height: 27vw;
    width: 100%;
    display: inline-block;
    margin-bottom: 0;
    padding: 0;
    object-fit: cover;
}

@media screen and (max-width:600px) {
    .accountinfo-bgImage {
        height: 27vw;
    }
}

@media screen and (min-width:600px) {
    .accountinfo-bgImage {
        height: 165px;
    }
}

.accountinfo-account-container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.accountinfo-accountIcon {
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    width: 80px;
    height: 80px;
    padding: 0;
    margin-top: -45px;
    margin-left: 20px;
    object-fit: cover;
}

.accountinfo-accountIcon-placeholder {
    width: 80px;
    height: 80px;
    padding: 0;
    margin-top: -40px;
    margin-left: 20px;
}

.accountinfo-sharebutton {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.accountinfo-share {
    width: 24px;
    height: 24px;
    margin: 13px 20px auto auto;
}

.accountinfo-accountName {
    padding: 0;
    margin: 13px auto 0px 20px;
    font-size: 16px;
    font-family: 'Noto Sans JP';
}

.accountinfo-accountId {
    color: #808080;
    font-size: 12px;
    font-family: 'Noto Sans JP';
    margin: 0;
    padding: 0;
    margin-left: 20px;
}

.accountinfo-accountDetail-container{
    margin: 12px 20px 30px 20px;
}
.accountinfo-accountDetail {
    font-size: 12px;
    line-height: 18px;
    font-family: Noto Sans JP;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    white-space: pre-wrap;
}
.accountinfo-url{
    font-size: 10px;
    font-family: 'Noto Sans JP';
    color: #00366A;
    margin-bottom: 3px;

    
}
.accountinfo-linkImg{
    width: 14px;
    height: 14px;
    margin-right:4px;
   
}

.accountinfo-linkContainer{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 300px; /* 省略せずに表示するサイズを指定 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;  
	-o-text-overflow: ellipsis; 
}