.itemComment-container {
    background-color: white;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
}

.itemcomment-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.itemcomment-header img {
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    object-fit: cover;
}

.itemcomment-header h2 {
    font-size: 12px;
    color: black;
    margin: 8px;
    width: calc(100% - 48px);
}

.itemComment-sharebutton {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.itemComment-container h1 {
    margin: 0;
    font-size: 16px;
    color: black;
    margin-bottom: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.itemComment-container p {
    margin: 0;
    font-size: 12px;
    color: black;
    white-space: pre-wrap;
}